import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import * as React from 'react';
import Location from 'src/modules/address/views/Location';
// import ClientAddress from 'src/modules/address/views/ClientAddress';

const namespacesRequired = ['common', 'location', 'not-found'];

const LandingPage: NextPage = () => {
  return (
    <>
      <div className="m-auto flex  flex-col items-center justify-center">
        <Location />
        {/* <ClientAddress /> */}
      </div>
    </>
  );
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespacesRequired)),
    },
  };
}

export default LandingPage;
